import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';

export interface TokenResult {
  token: string;
  expires: string;
  createdAt: string;
}

@Injectable({
  providedIn: 'root',
})

export class HubspotService {
  constructor(private api: ApiService) { }

  createVisitorTokenHubspot(email: string, firstName: string, lastName: string) {
    const body = {
      email,
      firstName,
      lastName,
    };

    return this.api.request<{ data: TokenResult }>('post', environment.env.TD_APP_HUBSPOT_TOKEN_GENERATE, body);
  }
}
